/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
.dntp-page {
	h1 {
		color: var(--c-dntp-primary);
	}

	.btn.btn-outline-dark {
		&:hover {
			background-color: var(--c-dntp-primary);
		}
	}

	.sidebar {
		background-color: var(--c-dntp-primary);
		// background-image: linear-gradient(
		// 	to right,
		// 	var(--c-dntp-primary) 5%,
		// 	var(--c-dntp-accent)
		// );

		ul li {
			a {
				color: var(--c-dntp-contrast);
				text-shadow: 0 1px rgba(0, 0, 0, 0.27);
			}

			&.active {
				background-color: var(--c-dntp-orange);
				a {
					color: var(--c-dntp-accent-contrast);
					text-shadow: 0 1px rgba(255, 255, 255, 0.27);
				}
			}

			.badge {
				background-color: var(--c-dntp-accent);
			}
		}
	}

	.header-menu,
	palga-menu ul {
		background-color: var(--c-dntp-background);
		// background-image: linear-gradient(
		// 	to bottom,
		// 	var(--c-dntp-primary),
		// 	var(--c-dntp-accent)
		// );
		border-radius: 0;

		li {
			a {
				color: var(--c-dntp-contrast);
				text-shadow: 0 1px rgba(0, 0, 0, 0.27);
			}

			&.active {
				background-color: var(--c-dntp-color);
			}

			&.active a {
				color: var(--c-dntp-background);
				text-shadow: none;
				box-shadow: inset 0px 3px 0 var(--c-dntp-accent);
			}

			&:hover {
				background-color: var(--c-dntp-accent);
				color: var(--c-dntp-accent-contrast);
				box-shadow: inset 0px -3px 0 var(--c-dntp-primary);
			}

			.dropdown-item {
				background-color: var(--c-dntp-color);
				color: var(--c-dntp-background);

				&:hover {
					background-color: var(--c-dntp-accent);
					color: var(--c-dntp-accent-contrast);
				}
			}
		}

		palga-menu-user .dropdown {
			background-color: var(--c-dntp-primary);
		}
	}

	.text-base-color {
		color: var(--c-dntp-contrast);
	}

	.page-item .page-link {
		background-color: var(--c-dntp-primary-contrast);
		color: var(--c-dntp-primary);
	}

	.page-item.active .page-link {
		background-color: var(--c-dntp-background);
		color: var(--c-dntp-color);
	}
}

palga-footer footer.dntp-page {
	background-color: var(--c-dntp-background);
	// background-image: linear-gradient(
	// 	to top,
	// 	var(--c-dntp-primary),
	// 	var(--c-dntp-accent)
	// );
	color: var(--c-dntp-color);
	a {
		color: var(--c-dntp-accent-contrast);
	}
}

.sidebar-logo {
	position: absolute;
	bottom: 0;
	padding: 0.618rem;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	z-index: -1;
}

.dntp-sidebar-image {
	width: 100%;
	// max-width: 7.5rem;

	display: inline-block;
	// box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.27);
}
