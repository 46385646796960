/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
@media print {
	// @page {
	//     margin: 20mm 5mm;
	// }

	html,
	body {
		height: initial !important;
	}

	body .vh-100,
	.h-100 {
		height: initial !important;
	}

	body {
		font-size: 0.8em;
	}

	.print-only {
		display: initial !important;
	}

	// elements & classes to hide when printing
	.no-print,
	header,
	palga-table-pagination,
	palga-footer,
	.sidebar,
	.toolbar,
	button {
		display: none !important;
	}

	a {
		text-decoration: none;
		color: #000;
	}

	.page-break {
		page-break-after: always;
		break-after: always;
	}

	#print-details .row {
		> *:first-child {
			float: left;
			width: 300px;
		}
		> *:last-child {
			float: right;
			width: 300px;
		}
	}

	table {
		width: auto !important;

		th,
		td {
			padding: 0px !important;
		}

		th.sortable::after,
		th.asc::after th.desc::after {
			display: none !important;
		}

		.badge {
			display: block !important;
		}
	}

	// prevent page break inside a .row
	.row,
	.row *,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	span,
	p {
		// break-inside: avoid;
	}

	// Bootstrap fix
	.container,
	.container-fluid {
		width: auto;
		display: block !important;
	}

	.main-row {
		min-height: 95vh;
		height: auto !important;
	}

	.main-content {
		margin-left: 0px !important;
	}

	.print-page {
		display: block;
		page-break-after: always;
	}
}
