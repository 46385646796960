/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
:root {
	--c-primary: #193770;

	--c-dntp-primary: #{$dntp-primary};
	--c-dntp-orange: #{$dntp-orange};
	--c-dntp-accent: #{$dntp-accent};
	--c-dntp-contrast: #{$dntp-contrast};
	--c-dntp-accent-contrast: #{$dntp-accent-contrast};
	--c-dntp-background: var(--c-dntp-primary);
	--c-dntp-color: var(--c-dntp-contrast);
}
