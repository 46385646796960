/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* Define form styling, input, textarea, buttons, override Bootstrap styling */

// input fields if they are clicked(touched) AND are invalid
// input fields if they are NOT clicked(touched), the form is submitted, AND the fields are invalid
input.ng-touched.ng-invalid,
textarea.ng-touched.ng-invalid,
select.ng-touched.ng-invalid,
form.ng-invalid.ng-submitted input.ng-invalid,
form.ng-invalid.ng-submitted textarea.ng-invalid,
form.ng-invalid.ng-submitted select.ng-invalid {
	border: 1px solid $invalid-field-color;
}

.form-check {
	display: flex;
	align-items: center;
	padding: 0px;
}

.form-control[type='checkbox'] {
	width: auto !important;
	display: inline-block;
	margin-right: 1em;
}

.full-width {
	width: 100%;
}
// Header of a request form section that is visible to the requester
.mat-typography .requester-form-section-header {
	background-color: $request-form-requester-section-header-background;
	color: $request-form-section-header-text;
	font-size: 1.5em;
	padding: 0.5em 0.75em;
	margin: 16px 0px !important;

	&.dntp-header {
		background-color: var(--c-dntp-primary);
		color: white;
	}

	&.palga-header {
		background-color: $sidebar-background;
		color: white;
	}
}

// Header of a request form section that is visible to Palga and/or the scientific council
.mat-typography .palga-form-section-header {
	background-color: $request-form-palga-section-header-background;
	color: $request-form-section-header-text;
	font-size: 1.5em;
	padding: 0.25em;
	margin: 16px 0px !important;
}

// Blue circle indicator for number of votes and comments
.badge-blue {
	background-color: $badge-background;
	color: $badge-text;
	margin-left: 0.5em;
}

// Individual row in list of votes
.vote-row {
	border: 1px solid $vote-row-border;
	padding: 1em;
}

.spaced-button {
	margin: 0.25em;
}

.mandatory-field {
	color: $form-mandatory-field;
	font-size: 0.8em;
}
