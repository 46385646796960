/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* You can add global styles to this file, and also import other style files */

// override bootstrap primary color before loading bootstrap
$theme-colors: (
	'primary': #337ab7,
);

/* Import custom SCSS files */
@import './assets/styles/colors.scss';
@import './assets/styles/form.scss';
@import './assets/styles/table.scss';
@import './assets/styles/print.scss';

@import './assets/styles/root';

@import './assets/styles/dntp-theme';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~@ng-select/ng-select/themes/default.theme.css';

/* Bootstrap override the default cursor on <a> tag without links */
a:not([href]) {
	cursor: pointer;
}

.no-padding {
	padding: 0;
}

.toolbar {
	border-top: 1px solid $light-gray;
	padding: 1em 0em;

	palga-button {
		margin-right: 1em;
	}
}

/* header menu background */
.bg-header-light {
	background-color: $header-background;
}

.text-base-color {
	color: $header-text;
}

body {
	font-size: 80%;
	// make scrollbar not alter the width
	overflow-y: overlay;
}

.ql-container {
	min-height: 200px;
}

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.h4,
.h3 {
	font-weight: 200;
	color: #555753;
	background-color: aliceblue;
	padding: 7px;
	margin: 16px 0px !important;
}

.print-only {
	display: none;
}

/* change sortable icons into button pointer on hover */
th.sortable:hover {
	cursor: pointer;
}

/* change icons into button pointer on hover */
.icon-button:hover {
	cursor: pointer;
}

// sidebar styling
.sidebar {
	background-color: $sidebar-background;
	z-index: 2;
	position: fixed;
	height: 95vh;
	margin-left: 12px; // STUPID HACK: for some reason the sidebar is 12px to the left since migrating bootstrap 4 -> 5..

	ul {
		padding: 1em 0em;
		display: block;
		height: calc(100% - 5vh); // 5vh is footer height
		overflow-y: auto;

		li {
			a {
				color: $sidebar-text;
				text-decoration: none;

				padding: 0.5em 1em;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.badge {
					background-color: $sidebar-badge-background;
					color: #fff;
				}
			}

			&:hover {
				background-color: $sidebar-hover-background;

				a {
					color: $sidebar-hover-text;
				}
			}

			&.active {
				background-color: $sidebar-active-link-background;

				a {
					color: $sidebar-active-text;
				}
			}
		}
	}
}

/* main content container */
.main-row {
	height: 95vh;
}

/* main content container for login page */
.login-main-row {
	height: 90vh;
}

/* take a bit more than main-row height for some extra padding */
.main-content {
	padding-top: 6vh;
	padding-bottom: 6vh;
}

/* sidebar logo */
.dntp-logo {
	height: 10vh;
	background-image: url('/assets/images/header-background.jpg');
}

.no-bg {
	background-image: none !important;
}

/* loading spinner center page */
.centered-spinner {
	position: fixed;
	top: 50%;
	left: 50%;
}

.bg-dntp-primary {
	background-color: var(--c-dntp-background);
}

.noopacity {
	opacity: 0;
}

.upper-first::first-letter {
	text-transform: capitalize;
}

.align-center {
	text-align: center;
}

.bold {
	font-weight: bold !important;
}

/* removes underlining for links on all pages */
a {
	text-decoration: none;
}

/* this fixes the grey box for ngx-file-drag-drop for bootstrap 5.2 */
/* https://stackoverflow.com/questions/75060270/updating-bootstrap-to-5-turns-emptyplaceholder-in-file-drag-drop-into-a-grey-loa */
.placeholder {
	background-color: transparent;
}
