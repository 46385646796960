/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* Define colors used in the application */

$invalid-field-color: hsl(0, 100%, 50%);
$side-menu-selected: hsl(208, 56%, 53%);
$global-text-color: hsl(0, 0%, 27%);

/* used for borders */
$light-gray: hsl(0, 0%, 75%);

/* Sidebar colors */
$sidebar-background: #f07426;
// $sidebar-background: #ef955dde;
$sidebar-text: hsl(0, 0%, 97%);
$sidebar-badge-background: hsl(219, 64%, 27%);
$sidebar-hover-background: hsl(0, 0%, 93%);
$sidebar-hover-text: hsl(160, 100%, 1%);
$sidebar-active-link-background: hsl(219, 64%, 27%);
$sidebar-active-text: hsl(0, 0%, 100%);

/* footer colors */
$footer-background: $sidebar-background;
$footer-background-transparent: hsla(212, 65%, 91%, 0);
$footer-text: $sidebar-text;

/* header colors */
$header-background: $sidebar-background;
$header-menu-item-selected: $sidebar-active-link-background;
$header-menu-item-hoover: $sidebar-hover-background;
$header-text: hsl(0, 0%, 96%);

/* claimed requests */
$row-claimed: hsl(81, 72%, 89%);

/* greyed out text override */
$text-greyed-out: hsl(240, 1%, 72%);

/* request form section headers */
$request-form-palga-section-header-background: hsl(33, 72%, 92%);
$request-form-requester-section-header-background: hsl(208, 100%, 97%);
$request-form-section-header-text: hsl(90, 2%, 33%);

/* mandatory field warning in forms */
$form-mandatory-field: hsl(0, 100%, 27%);

/* vote/comment count badge colors */
$badge-background: hsl(215, 100%, 19%);
$badge-text: hsl(0, 0%, 100%);

/* border color of a single row in the list of votes */
$vote-row-border: hsl(0, 0%, 87%);

// DNTP specific color schemes
$dntp-orange: #e39774;
$dntp-primary: hsl(191, 33%, 52%);
$dntp-contrast: hsl(0, 0%, 100%);
$dntp-accent: hsl(196, 39%, 32%);
$dntp-accent-contrast: #eeeeee;
