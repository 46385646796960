/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* Define table styling, override Bootstrap styling */
@import 'colors.scss';

th.sortable::after {
	display: inline-block;
	content: '';
	vertical-align: sub;
	// https://icons.getbootstrap.com/icons/chevron-expand/
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-expand" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"/></svg>');
	background-repeat: no-repeat;
	background-size: 1rem 1rem;
	height: 1rem;
	width: 1rem;
}

th.desc::after {
	display: inline-block;
	content: '';
	vertical-align: sub;
	// https://icons.getbootstrap.com/icons/chevron-down/
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
	background-repeat: no-repeat;
	background-size: 1rem 1rem;
	height: 1rem;
	width: 1rem;
}

th.asc::after {
	display: inline-block;
	content: '';
	vertical-align: sub;
	// https://icons.getbootstrap.com/icons/chevron-up/
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/></svg>');
	background-repeat: no-repeat;
	background-size: 1rem 1rem;
	height: 1rem;
	width: 1rem;
}

// make selected row green
// it needs the !important postfix or the tr:nth-child(even) rule would keep overriding it
tr.selected > td {
	background-color: $row-claimed !important;
}

// the bootstrap class text-muted didn't have enough contrast compared to the regular text
.text-muted {
	color: $text-greyed-out !important;
}

.table-fixed {
	table-layout: fixed;
}
